import Vue from "vue";

const menu = "dinningHall/mealconsumption/";

export default {
    pagination(dr) {
        return Vue.prototype.$http.post(menu + "checkliquidation", dr);
    },
    setStatusLiquidation(mc) {
        return Vue.prototype.$http.post(menu + "setstatusliquidation", mc);
    }
};