import Vue from "vue";

const menu = "dinningHall/mealconsumption/";

export default {
    pagination(dtr) {
        return Vue.prototype.$http.post(menu + "platoscentrocosto", dtr);
    },

    excel(dtr) {
        return Vue.prototype.$http.post(menu + "platoscentrocostoreport", dtr, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
        });
    },

    loadSAP(dr, ussUserCode, period) {
        return Vue.prototype.$http.post(menu + "loadSAP", dr, { params: { UssUserCode: ussUserCode, Period: period } });
    },
    checkLiquidation(dr) {
        return Vue.prototype.$http.post(menu + "checkliquidation", dr);
    },

    PDFConsumos(filter) {
        console.log(filter);
        return Vue.prototype.$http.post(menu + "pdfConsumos", filter, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
        });
    }
};