import Vue from "vue";

const menu = "dinningHall/mealconsumption/";

export default {
    pagination(dr){
      return Vue.prototype.$http.post(menu+"liquidationlist",dr);
  },
  save(dr){
    return Vue.prototype.$http.post(menu+"liquidationremove",dr);
},
excel(dtr,usrid,row) {
  console.log("excel",dtr,usrid,row);
  dtr.filter={LqdID:row.LqdID,Period:row.TypeLiquidationText+' '+row.LqdGlosa}
  return Vue.prototype.$http.post(menu + "platoscentrocostoreport", dtr, {
    responseType: "blob",
    headers: {
      "Content-Type": "application/json",
    },
  });
},
};