import Vue from "vue";

const menu = "dinningHall/mealconsumption/";

export default {
    pagination(dtr) {
        return Vue.prototype.$http.post(menu + "reaservasdetalladas", dtr);
    },
    excel(dtr) {
        return Vue.prototype.$http.post(menu + "reaservasdetalladasR", dtr, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
        });
    },
};